import React from "react";

const Footer = () => {
  return (
    <>
      <section id="footer" className="footer_section side_spacing">
        <div className="section_top_large">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4 text-center">
              <div className="footer-scanner-img">
                <img
                  src="/images/footer-imgs/QR-phase1.png"
                  alt="QR Code 1"
                />
              </div>
              <span className="footer-scanner-text">
                Earth 1 By VTP Luxe PHASE 1
              </span>
              <div className="d-flex align-items-center justify-content-center gap-1">
                <div>
                  <span className="mahalogo">
                    MahaRERA Reg. No: &nbsp; P52100048489
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 text-center">
              <div className="footer-scanner-img">
                <img
                  src="/images/footer-imgs/QR-phase2.png"
                  alt="QR Code 1"
                />
              </div>
              <span className="footer-scanner-text">
                Earth 1 By VTP Luxe PHASE 2
              </span>
              <div className="d-flex align-items-center justify-content-center gap-1">
                <div>
                  <span className="mahalogo">
                    MahaRERA Reg. No: &nbsp; P52100051025
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 text-center">
              <div className="footer-scanner-img">
                <img
                  src="/images/footer-imgs/QR-phase3.webp"
                  alt="QR Code 1"
                />
              </div>
              <span className="footer-scanner-text">
                Earth 1 By VTP Luxe PHASE 3
              </span>
              <div className="d-flex align-items-center justify-content-center gap-1">
                <div>
                  <span className="mahalogo">
                    MahaRERA Reg. No: &nbsp; P52100052414
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <p className="footer_scan_text">
                All projects are registered under MahaRERA and is available on website - <a href="https://maharera.mahaonline.gov.in" target="_blank" rel="noopener noreferrer">http://maharera.mahaonline.gov.in/</a>
              </p>
            </div>
            <hr />
            <div className="footer-text-container">
              <p className="text-center mb-0">
                &copy; Copyright 2024 Official VTP Realty Website. All Rights Reserved.
              </p>
              <p className=" text-center mb-1">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#disclaimerModal"
                  className="disc-btn"
                  style={{ cursor: "pointer", border: 'none', background: 'none' }}
                >
                  Disclaimer
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
