import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CountryDropdown from "./CountryDropdown";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { isValidPhoneNumber } from 'libphonenumber-js';
import Widget from "./TurnstileWidget";

const Enquirynow = ({ showCallbackDropdown, onClick, closePopup }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const [otherInfo, SetOtherInfo] = useState({
    gclid: searchParams.get("gclid") || "",
    sfcid: searchParams.get("sfcid") || "",
    sfid: searchParams.get("sfid") || "",
    utm_source: searchParams.get("utm_source") || "",
    utm_medium: searchParams.get("utm_medium") || "",
    utm_campaign: searchParams.get("utm_campaign") || "",
    sk_referrer: searchParams.get("sk_referrer") || "",
  });

  const [captchaValidated, setCaptchaValidated] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(""); 

  const getValidationSchema = (showCallbackDropdown) => Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-z\s'-]+$/, "Letters only please")
      .required("First name is required!"),
    last_name: Yup.string()
      .matches(/^[A-Za-z\s'-]+$/, "Letters only please")
      .required("Last name is required!"),
    mobile: Yup.string()
      .required("Mobile number is required!")
      .matches(/^\d+$/, "Invalid phone number")
      .test('is-valid-phone', 'Invalid phone number', function (value) {
        const { country_code } = this.parent;
        if (!value) return false;
        const cleanedValue = value.replace(/\D/g, '');

        if (!cleanedValue) {
          return this.createError({ message: 'Phone number cannot be empty after cleaning.' });
        }
        const fullNumber = `${country_code}${cleanedValue}`;
        const isValid = isValidPhoneNumber(fullNumber);
        if (!isValid) {
          return this.createError({ message: 'Invalid number!' });
        }

        return isValid;
      }),

    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
    country_code: Yup.string().required("Country code is required!"),
    term_condition: Yup.boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("Acceptance of terms is required!"),
  });

  const handlerSubmit = async (formVal) => {
    if (!captchaValidated) {
      toast.error("Please complete the CAPTCHA to proceed.");
      return;
    }

    let CampaignID = "7015j000000Pub1";
    let secondary_source = "google";

    if (formVal.utm_source === "facebook") {
      CampaignID = "7015j000000PukD";
      secondary_source = formVal.utm_source;
    }

    if (
      formVal.utm_source === "facebook" &&
      formVal.utm_campaign === "facebook-altitude"
    ) {
      CampaignID = "7015j000000PukD";
      secondary_source = formVal.utm_source;
    }
    if (formVal.utm_source === "linkedin") {
      CampaignID = "7015j000000Punh";
      secondary_source = formVal.utm_source;
    }
    if (formVal.sfid) {
      CampaignID = formVal.sfid;
      secondary_source = formVal.utm_source;
    }

    if (formVal.sk_referrer === "international") {
      if (formVal.utm_source === "google") {
        CampaignID = "7015j000000Puaw";
      }
      if (formVal.utm_source === "facebook") {
        CampaignID = "7015j000000Puk8";
      }
      if (formVal.utm_source === "linkedin") {
        CampaignID = "7015j000000Punr";
      }
      secondary_source = formVal.utm_source;
    }

    if (formVal.sk_referrer === "rom") {
      if (formVal.utm_source === "google") {
        CampaignID = "7015j000000Puey";
      }
      if (formVal.utm_source === "facebook") {
        CampaignID = "7015j000000Puk3";
      }
      if (formVal.utm_source === "linkedin") {
        CampaignID = "7015j000000Puo0";
      }
      secondary_source = formVal.utm_source;
    }

    let CampaignNewID = null;
    if (formVal.utm_source !== "google") {
      CampaignNewID = CampaignID;
    }

    const formData = {
      formData: {
        FirstName: formVal.first_name,
        LastName: formVal.last_name,
        Email: formVal.email,
        ProjectName: "Earth 1",
        SubProjectName: "Earth One",
        Company: "",
        CampaignId: CampaignNewID,
        PrimarySource: "Digital",
        SecondarySource: secondary_source,
        UtmCampaign: formVal.utm_campaign,
        UtmMedium: formVal.utm_medium,
        UtmSource: formVal.utm_source,
        CountryCode: formVal.country_code,
        Mobile: formVal.mobile,
        GCLID: formVal.gclid,
        Comment: " ",
        captchaToken,
      },
    };

    console.log("Captcha Token: ", captchaToken);
    console.log("Form Data: ", formData);

    const loadingToast = toast.loading("Loading...", {
      position: toast.POSITION.TOP_CENTER,
    });

    console.log('formData - ', formData);

    const config = {
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/salesforce/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      const response = await axios(config);
      toast.dismiss(loadingToast);
      let downloadBrochure = "";
      if (closePopup) {
        downloadBrochure = localStorage.getItem("downloadBrochure");
        closePopup.current.click();
      }

      if (response.data.status === true) {
        localStorage.setItem("downloadBrochure", downloadBrochure);
        localStorage.setItem("formData", JSON.stringify(formData.formData));
        if (closePopup) {
          closePopup.current.click();
        }
        navigate("/thank-you");
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error("Request failed!");
      console.log("Salesforce error -", error);
    }
  };

  const handleCaptchaSuccess = (token) => {
    setCaptchaToken(token);
    setCaptchaValidated(true);
    console.log("Captcha validated:", token);
  };

  const handleCaptchaError = () => {
    setCaptchaValidated(false);
    console.log("Captcha validation failed.");
  };

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        country_code: "+91",
        mobile: "",
        email: "",
        term_condition: true,
        gclid: otherInfo.gclid,
        sfcid: otherInfo.sfcid,
        sfid: otherInfo.sfid,
        utm_source: otherInfo.utm_source,
        utm_medium: otherInfo.utm_medium,
        utm_campaign: otherInfo.utm_campaign,
        sk_referrer: otherInfo.sk_referrer,
        download_brochure: ""
      }}
      validationSchema={getValidationSchema(showCallbackDropdown)}
      onSubmit={async (values, { setSubmitting }) => {
        handlerSubmit(values);
      }}
    >
      {({ isSubmitting, setFieldValue }) => {
        return (
          <Form>
            <div className="enquirynow_form">
              <h2 className="section-title">
                ENQUIRE &nbsp;
                <span className="title-bold">NOW</span>
              </h2>
              <div className="row">
                <div className="col-md-6 col-12 mb-3">
                  <Field type="hidden" id="gclid" name="gclid" />
                  <Field type="hidden" id="sfcid" name="sfcid" />
                  <Field type="hidden" id="sfid" name="sfid" />
                  <Field type="hidden" id="utm_source" name="utm_source" />
                  <Field type="hidden" id="utm_medium" name="utm_medium" />
                  <Field type="hidden" id="utm_campaign" name="utm_campaign" />
                  <Field type="hidden" id="sk_referrer" name="sk_referrer" />
                  <Field
                    type="hidden"
                    id="download_brochure"
                    name="download_brochure"
                  />

                  <Field
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="form-control form-field custom-input  rounded-0"
                    placeholder="First Name*"
                  />
                  <ErrorMessage name="first_name" component="span" className="error" />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <Field
                    type="text"
                    id="last_name"
                    name="last_name"
                    className="form-control form-field  custom-input  rounded-0"
                    placeholder="Last Name*"
                  />
                  <ErrorMessage name="last_name" component="span" className="error" />
                </div>
                <div className="col-md-12 col-12 mb-3">
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="form-control form-field  custom-input  rounded-0"
                    placeholder="Email*"
                  />
                  <ErrorMessage name="email" component="span" className="error" />
                </div>
                <div className="col-md-5 col-4 mb-3">
                  <CountryDropdown />
                </div>
                <div className="col-md-7 col-8 mb-3 pl-0">
                  <Field
                    type="text"
                    id="mobile"
                    name="mobile"
                    className="form-control form-field  custom-input  rounded-0"
                    placeholder="Phone*"
                  />
                  <ErrorMessage name="mobile" component="span" className="error" />
                </div>
                <div className="col-md-7 col-12 mb-3">
                  <Widget onSuccess={handleCaptchaSuccess} onError={handleCaptchaError} />
                </div>
                <div className="col-md-12 col-12 mb-3">
                  <div className="form-check">
                    <div className="d-flex align-items-start gap-2">
                      <Field
                        className="form-check-input"
                        type="checkbox"
                        name="term_condition"
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        I hereby authorize to send notification on SMS, Calls,
                        RCS, WhatsApp.
                      </label>
                    </div>
                    <ErrorMessage name="term_condition" component="span" className="error" />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button type="submit" className="header-btn submit-btn" disabled={isSubmitting}>ENQUIRE NOW</button>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  );
};

export default Enquirynow;
