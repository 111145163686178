import React, { useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
  
	const { pathname } = useLocation();

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

  return (
    <>
     <div className="main-content">
			  <ToastContainer />
        <Header />
        <Outlet />
        <Footer />
     </div>
    </>
  );
};

export default Layout;