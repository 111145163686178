import React from "react";

const NotFound = () => {
  return (
    <>
      <div className="error_page">
        <a href="/">
          <div className="">
            <img
              className="fill_img"
              src="/images/errorpage.webp"
              alt="pagenot found"
            />
          </div>
        </a>
      </div>
    </>
  );
};

export default NotFound;
