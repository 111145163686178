export const highlightsData = [
  {
    imgSrc: "/images/ProjectHightlights/apartments.png",
    alt: "Image 1",
    title: "Tower",
    description: "35 storey in the launched towers",
  },
  {
    imgSrc: "/images/ProjectHightlights/gate.png",
    alt: "Image 2",
    title: "Entrance",
    description: "Grand entrance gate with security cabin",
  },
  {
    imgSrc: "/images/ProjectHightlights/home-automation.png",
    alt: "Image 3",
    title: "Smart Home Automation",
    description:
      "Provision for controlling Lights, AC, Fan & Geyser via a mobile app",
  },
  // {
  //   imgSrc: "/images/ProjectHightlights/digital-door.png",
  //   alt: "Image 4",
  //   title: "Digital Lock",
  //   description: "Premium veneer finish main door with digital lock",
  // },
  {
    imgSrc: "/images/ProjectHightlights/electric-generator.png",
    alt: "Image 5",
    title: "4+ Acres of Larger than Life Amenities",
    description: "A larger than life arena with world class amenities for people of all age groups.",
  },
  // {
  //   imgSrc: "/images/ProjectHightlights/power-bank.png",
  //   alt: "Image 6",
  //   title: "Charging Point",
  //   description:
  //     "Provision for electric vehicles charging points in common area",
  // },
  {
    imgSrc: "/images/ProjectHightlights/shield.png",
    alt: "Image 7",
    title: "Security",
    description:
      "3-tier security system and video door phone connected to the smartphone",
  },
  {
    imgSrc: "/images/ProjectHightlights/marble.png",
    alt: "Image 8",
    title: "Marble/Large Windows",
    description: "Large sized marble finish glazed vitrified tiles",
  },
];

export const benefitsData = [
  {
    imgSrc: "/images/LiveClose/icon-benefits01.svg",
    alt: "icon-1",
    title: "Easy Commute",
    description:
      "Stay Connected, Every area is accessible in a very short span of time",
  },
  {
    imgSrc: "/images/LiveClose/icon-benefits02.svg",
    alt: "icon-2",
    title: "Hinjewadi IT Park",
    description:
      "Don't waste your time travelling when it comes to work. Office areas are close by.",
  },
  {
    imgSrc: "/images/LiveClose/icon-benefits03.svg",
    alt: "icon-3",
    title: "Top Notch Schools & Colleges",
    description:
      "All top schools & colleges in the near vicinity of the project",
  },
  {
    imgSrc: "/images/LiveClose/icon-benefits04.svg",
    alt: "icon-4",
    title: "Party & Entertainment Avenues",
    description:
      "It's not bad to take some time out from your busy schedule and enjoy the life around you.",
  },
];

// export const Elevationimages = [
//   "/images/ProjectGallery/gallery1.webp",
//   "/images/ProjectGallery/gallery2.webp",
//   "/images/ProjectGallery/gallery3.webp",
//   "/images/ProjectGallery/gallery2.webp",
//   "/images/ProjectGallery/gallery3.webp",
//   "/images/ProjectGallery/gallery1.webp",
// ];
// export const Exteriorimages = [
//   "/images/ProjectGallery/exterior1.webp",
//   "/images/ProjectGallery/exterior2.webp",
//   "/images/ProjectGallery/exterior3.webp",
//   "/images/ProjectGallery/exterior4.webp",
//   "/images/ProjectGallery/exterior5.webp",
//   "/images/ProjectGallery/exterior6.webp",
//   "/images/ProjectGallery/exterior7.webp",
// ];
export const Amenitiesimages = [
  "/images/Amenities/amenities1.webp",
  "/images/Amenities/amenities2.webp",
  "/images/Amenities/amenities3.webp",
  "/images/Amenities/amenities4.webp",
  "/images/Amenities/amenities5.webp",
  "/images/Amenities/amenities6.webp",
  "/images/Amenities/amenities7.webp",
];
