import React, { useEffect } from 'react';

const PopupModal = () => {
  useEffect(() => {
    const bootstrap = require('bootstrap');
    const modal = new bootstrap.Modal(document.getElementById('welcomeModal'));
    modal.show();
  }, []);

  return (
    <div
      className="modal fade"
      id="welcomeModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <a
              href="https://doosridiwali.vtprealty.in/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="popup-img"
                src="./images/pop-up.webp"
                alt="pop-up"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
