import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Layout from '../layout/layout';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import ThankYou from '../pages/ThankYou';

const router = () => createBrowserRouter(
    createRoutesFromElements(
        <>
        <Route element={<Layout />}>
            <Route index path='/' element={<Home />} />
            <Route path='/thank-you' element={<ThankYou />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        </>
    )
);

export default router;